import $ from "jquery";
import "slick-carousel";

export default class PageTop {
  constructor() {
    this.container = document.getElementsByClassName("js-page-top")[0];
    this.init();
  }

  init() {
    if (!this.container) return false;

    const faqSliderContainers = document.getElementsByClassName("js-top-faq-list");
    if (faqSliderContainers.length) {
      Array.prototype.forEach.call(faqSliderContainers, elm => {
        this.faqSlider(elm);
      });
    }

    this.bindEvents();
  }

  bindEvents() {
  }

  faqSlider(container) {
    const sliderOpt = {
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: false,
      dots: true,
      infinite: true,
      centerMode: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
          }
        }
      ]
    };
    let slider = "";

    if (container) {
      slider = $(container).slick(sliderOpt);
    }
  }
}
