import { Stage, Shape, Ticker } from '@createjs/easeljs';
import _ from "lodash";

export default class KvAnimation {
  constructor() {
    this.container = document.getElementsByClassName("js-top-kv")[0];
    this.mql = window.matchMedia('only screen and (max-width: 767px)');
    this.canvas = document.getElementById("kvCanvas");
    this.fadeThreshold;       // 玉を消し始める距離
    this.startTime;
    this.firstEndTime = 2000;
    this.firstSpeedRate = 8;  // 初期表示のスピード
    this.isAnimating = true;
    this.timeoutId;
    this.stage;
    this.radius = 18;               // 玉の最大サイズ
    this.ballCreationInterval = 8;  // 玉の生成間隔計算用
    this.frameCounter = 0;
    this.init();
  }

  init() {
    if (!this.container) return false;
    if (!this.canvas) return false;

    const headerElm = document.getElementsByClassName('js-header')[0];
    this.stage = new Stage(this.canvas);

    this.setConfig();
    this.resizeCanvas();
    this.bindEvents();

    _.delay(() => {
      this.startTime = Ticker.getTime();
      Ticker.framerate = 120;
      Ticker.addEventListener("tick", this.tick.bind(this));

      this.container.classList.add('is-active-end');
      if (headerElm) {
        headerElm.classList.add('is-show');
      }
    }, 800);
  }

  bindEvents() {
    // リサイズイベント
    window.addEventListener("resize", () => {
      if (this.timeoutId) {
        return;
      }
      this.timeoutId = setTimeout(() => {
        this.timeoutId = 0;
        this.resizeCanvas();
        this.isAnimating = false;
        this.setConfig();
        this.restartAnimation();
      }, 500);
    });
  }

  tick(event) {
    if (!this.isAnimating) return;
    const elapsedTime = Ticker.getTime() - this.startTime;
    const firstFlg = (elapsedTime < this.firstEndTime);

    this.frameCounter++;

    if (firstFlg) {
      if (this.frameCounter % (this.ballCreationInterval / this.firstSpeedRate) === 0) {
        this.createBall();
        this.frameCounter = 0;
      }
    } else {
      if (this.frameCounter % this.ballCreationInterval === 0) {
        this.createBall();
        this.frameCounter = 0;
      }
    }

    this.stage.update(event);
  }

  // 玉を生成
  createBall() {
    const ball = new Shape();
    const radius = this.radius;
    const centerX = this.canvas.width / 2;
    const centerY = this.canvas.height / 2;
    ball.graphics.beginFill(this.randomColor()).drawCircle(0, 0, radius);
    ball.scale = 0.01;                // 初期のスケール
    ball.scaleGrowRate = 0.002;       // スケールの成長速度
    ball.alpha = 0;                   // 透明度
    ball.alphaGrowRate = 0.005;       // 透明度の成長速度
    ball.x = centerX;
    ball.y = centerY;
    ball.creationTime = Ticker.getTime(); // 生成時のタイムスタンプ
    ball.isFading = false;
    ball.firstSpeedRate = this.firstSpeedRate;

    ball.angle = 0;                   // 初期角度
    ball.angularVelocity = -0.0010;   // 角速度（反時計回りの速度）
    ball.angleRatio = 1;              // 角度をだんだん浅くするための値

    // 速度設定
    // const speed = Math.random() * 10 + 5;
    ball.speed = 1;
    ball.startAngle = ball.angle = Math.random() * Math.PI * 2;

    this.stage.addChild(ball);

    ball.addEventListener("tick", event => {
      const elapsedTime = Ticker.getTime() - this.startTime;
      const firstFlg = (elapsedTime < this.firstEndTime);

      if (!firstFlg) {
        ball.firstSpeedRate = 1;
      }

      // 角度を更新
      ball.angleRatio -= 0.001;
      ball.angle += ball.angularVelocity * ball.angleRatio * ball.firstSpeedRate;

      // 更新する位置を設定
      ball.vx = Math.cos(ball.angle) * ball.speed * ball.firstSpeedRate;
      ball.vy = Math.sin(ball.angle) * ball.speed * ball.firstSpeedRate;

      // スケールを更新
      if (ball.scale < 1) {
        ball.scale += ball.scaleGrowRate * ball.firstSpeedRate;
      }

      // 中央からの距離
      const distanceFromCenter = Math.sqrt(Math.pow(ball.x - centerX, 2) + Math.pow(ball.y - centerY, 2))
      if (distanceFromCenter > this.fadeThreshold) {
        ball.isFading = true;
      }

      if (!ball.isFading) {
        // 透明度を更新
        if (ball.alpha < 1) {
          ball.alpha += ball.alphaGrowRate * ball.firstSpeedRate;
          if (ball.alpha >= 1) {
            ball.alpha = 1;
          }
        }

        // 位置を更新
        ball.x += ball.vx;
        ball.y += ball.vy;

        // キャンバス外に出たら削除
        if (
          ball.x + radius < 0 ||
          ball.y + radius < 0 ||
          ball.x - radius > this.canvas.width ||
          ball.y - radius > this.canvas.height
        ) {
          this.stage.removeChild(ball);
        }
      } else {
        // 透明度を更新
        ball.alpha -= 0.02 * ball.firstSpeedRate;

        // 位置を更新
        ball.x += ball.vx;
        ball.y += ball.vy;

        if (ball.alpha <= 0) {
          this.stage.removeChild(ball);
        }
      }
    });
  }

  // ランダムに色を返却
  randomColor() {
    const colors = ['#FFA498', '#4AF278', '#2D79E2'];
    return colors[Math.floor(Math.random() * colors.length)];
  }

  // キャンバスサイズを設定
  resizeCanvas() {
    const offsetTop = this.canvas.offsetTop || 0;
    const w = window.innerWidth;
    let h = (this.container.clientHeight - offsetTop) * 2;

    this.canvas.width = w;
    this.canvas.height = h;
    this.stage.update();

    // 消え始める距離
    this.fadeThreshold = this.canvas.height / 2 * 0.9;
  }

  // アニメーションリスタート
  restartAnimation() {
    this.stage.removeAllChildren();
    this.stage.clear();
    this.isAnimating = true;
    this.startTime = Ticker.getTime();
    this.frameCounter = 0;
  }

  setConfig() {
    if (this.mql.matches) {
      // sp
      this.radius = 18;
      this.ballCreationInterval = 8;
    } else {
      // pc
      this.radius = 20;
      this.ballCreationInterval = 12;
    }
  }
}
