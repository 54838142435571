export default class PageNews {
  constructor() {
    this.container = document.getElementsByClassName("js-news-list")[0];
    this.nextShowCount = 10;
    this.init();
  }

  init() {
    if (!this.container) return false;

    this.bindEvents();

    const contentElms = this.container.getElementsByClassName('js-news-list-content');
    if (contentElms.length) {
      Array.prototype.forEach.call(contentElms, contentElm => {
        const moreBtn = contentElm.getElementsByClassName('js-news-list-more')[0];
        this.moreNext(contentElm, moreBtn);
      });
    }
  }

  bindEvents() {
    // タブ切り替え
    const tabElms = this.container.getElementsByClassName('js-news-list-tab');
    if (tabElms.length) {
      Array.prototype.forEach.call(tabElms, elm => {
        elm.addEventListener('click', e => {
          e.preventDefault();
          const type = elm.getAttribute('data-type') || "";
          this.changeTab(type);
        });
      });
    }

    // もっと見るボタン
    const contentElms = this.container.getElementsByClassName('js-news-list-content');
    if (contentElms.length) {
      Array.prototype.forEach.call(contentElms, contentElm => {
        const moreBtn = contentElm.getElementsByClassName('js-news-list-more')[0];
        if (moreBtn) {
          moreBtn.addEventListener('click', e => {
            e.preventDefault();
            this.moreNext(contentElm, moreBtn);
          });
        }
      });
    }
  }

  moreNext(contentElm, moreBtn) {
    // is-hideクラスを削除
    const newsListElms = contentElm.getElementsByClassName('js-news-list-item');
    if (newsListElms.length) {
      let count = 0;
      Array.prototype.forEach.call(newsListElms, elm => {
        if (elm.classList.contains('is-hide')) {
          if (count < this.nextShowCount) {
            elm.classList.remove('is-hide');
            count++;
          }
        }
      });

      let hideCount = 0;
      Array.prototype.forEach.call(newsListElms, elm => {
        if (elm.classList.contains('is-hide')) {
          hideCount++;
        }
      });

      if (hideCount == 0) {
        if (moreBtn) {
          moreBtn.classList.add('is-hide');
        }
      }
    }
  }

  changeTab(type) {
    // タブのアクティブを更新
    const tabElms = this.container.getElementsByClassName('js-news-list-tab');
    if (tabElms.length) {
      Array.prototype.forEach.call(tabElms, elm => {
        const elmType = elm.getAttribute('data-type') || "";
        if (elmType == type) {
          elm.classList.add('is-active');
        } else {
          elm.classList.remove('is-active');
        }
      });
    }

    // コンテンツのアクティブを更新
    const tabContentElms = this.container.getElementsByClassName('js-news-list-content');
    if (tabContentElms.length) {
      Array.prototype.forEach.call(tabContentElms, elm => {
        const elmType = elm.getAttribute('data-type') || "";
        if (elmType == type) {
          elm.classList.add('is-active');
        } else {
          elm.classList.remove('is-active');
        }
      });
    }
  }
}
