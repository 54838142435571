
export default class Header {
  constructor() {
    this.container = document.getElementsByClassName("js-header")[0];
    this.mql = window.matchMedia('only screen and (max-width: 767px)');
    this.timeoutId;
    this.init();
  }

  init() {
    if (!this.container) return false;

    this.bindEvents();
  }

  bindEvents() {
    // SPメニューボタン
    const spMenuBtnElm = this.container.getElementsByClassName('js-header-sp-menu-btn')[0];
    if (spMenuBtnElm) {
      spMenuBtnElm.addEventListener('click', e => {
        e.preventDefault();
        if (spMenuBtnElm.classList.contains('is-active')) {
          spMenuBtnElm.classList.remove('is-active');
          document.documentElement.classList.remove("is-unscrollable");
        } else {
          spMenuBtnElm.classList.add('is-active');
          document.documentElement.classList.add("is-unscrollable");
        }
      });
    }

    // リサイズイベント
    window.addEventListener("resize", () => {
      if (this.timeoutId) {
        return;
      }
      this.timeoutId = setTimeout(() => {
        this.timeoutId = 0;
        this.resize();
      }, 500);
    });
  }

  resize() {
    // リサイズ時にpc幅でspメニュー表示解除
    const spMenuBtnElm = this.container.getElementsByClassName('js-header-sp-menu-btn')[0];
    if (spMenuBtnElm) {
      if (!this.mql.matches) {
        spMenuBtnElm.classList.remove('is-active');
        document.documentElement.classList.remove("is-unscrollable");
      }
    }
  }
}
