export default class ImgMap {
  constructor() {
    this.container = document.getElementsByClassName('js-img-map');
    this.init();
  }

  init() {
    if (!this.container.length) return false;

    Array.prototype.forEach.call(this.container, container => {      
      this.resizeMap(container);
    });
  }

   resizeMap(container) {
    var image = document.getElementById('responsive-image');
    var originalWidth = 1160; // 画像の元の幅を指定
    var originalHeight = 1160; // 画像の元の高さを指定
  
    var currentWidth = image.clientWidth;
    var scaleFactor = currentWidth / originalWidth;

    var map = document.getElementsByTagName('area');
    // console.log(map);

      var cd_1 = [647,809,1033,1070];
      var cd_2 = [173,800,494,1036];
      var cd_3 = [731,7,845,139];
      var cd_4 = [825,472,1134,722];
      var cd_5 = [261,416,372,599];
      var cd_6 = [24,517,83,606];
      var cd_7 = [679,728,777,803];

    for (var i = 0; i < map.length; i++) {
      //var coords = map[i].getAttribute('coords').split(',');
      var cd = '';
      if(i == 0) {
        cd = cd_1;
      } else if(i == 1) {
        cd = cd_2;
      } else if(i == 2) {
        cd = cd_3;
      } else if(i == 3) {
        cd = cd_4;
      } else if(i == 4) {
        cd = cd_5;
      } else if(i == 5) {
        cd = cd_6;
      } else if(i == 6) {
        cd = cd_7;
      }

      for (var j = 0; j < cd.length; j++) {
        cd[j] = Math.round(cd[j] * scaleFactor);
      }
      map[i].setAttribute('coords', cd.join(','));
    }
  }
}
