export default class KvBg {
  constructor() {
    this.container = document.getElementsByClassName('js-kv-bg');
    this.init();
  }

  init() {
    if (!this.container.length) return false;

    Array.prototype.forEach.call(this.container, container => {
      this.setAnimationDelayStyle(container);
    });
  }

  // 内部のspan要素にランダムなanimationDelayスタイルを設定
  setAnimationDelayStyle(container) {
    const spanElms = container.getElementsByTagName('span');

    if (spanElms.length) {
      Array.prototype.forEach.call(spanElms, elm => {
        const delay = Math.random() * 2;
        elm.style.animationDelay = `${delay}s`;
      });
    }
  }
}
