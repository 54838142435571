import $ from "jquery";
import Header from "./Header";
import PageTop from "./PageTop";
import NewsList from "./NewsList";
import TopKvAnimation from "./TopKvAnimation";
import Anchor from "./Anchor";
import KvBg from "./KvBg";
import Modal from "./Modal";
import ImgMap from "./ImgMap";

$(() => {
  // ページ表示
  setTimeout(() => {
    document.documentElement.classList.add("is-render");
  }, 200);

  // ヘッダー
  if (document.getElementsByClassName("js-header")[0]) {
    new Header();
  }

  // TOPページ
  if (document.getElementsByClassName("js-page-top")[0]) {
    new PageTop();
  }

  // 一覧
  if (document.getElementsByClassName("js-news-list")[0]) {
    new NewsList();
  }

  // TopKvAnimation
  if (document.getElementsByClassName("js-top-kv")[0]) {
    new TopKvAnimation();
  }

  // TopKvAnimation
  if (document.getElementsByClassName("js-anchor").length) {
    new Anchor();
  }

  // KvBg
  if (document.getElementsByClassName("js-kv-bg").length) {
    new KvBg();
  }

  // Modal
  if (document.getElementsByClassName("js-modal").length) {
    new Modal();
  }

  // ImgMap
  if (document.getElementsByClassName("js-img-map").length) {
    //new ImgMap();
    $(window).on('resize load',function(){
      new ImgMap();

      let resizeTimeout;
      window.addEventListener('resize', function() {
          // 以前のタイマーをクリア
          clearTimeout(resizeTimeout);
          resizeTimeout = setTimeout(function() {
            new ImgMap();
          }, 1000);
      });
      
    });
  }
});
