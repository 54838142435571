import $ from "jquery";
import _ from "lodash";

export default class Anchor {
  constructor() {
    this.target = document.getElementsByClassName('js-anchor');
    this.speed = 500;
    this.init();
  }

  init() {
    // ページ読み込み時
    const hash = location.hash;
    if (hash) {
      if ($(hash).length) {
        _.delay(() => {
          let pos = $(hash).offset().top;
          $('body,html').animate({ scrollTop: pos }, this.speed, 'swing');
        }, 500);
      }
    }

    if (!this.target.length) return false;

    this.bindEvents();
  }

  /**
   * イベント処理設定
   * @return {undefined}
   */
  bindEvents() {
    // click event
    Array.prototype.forEach.call(this.target, elm => {
      elm.addEventListener("click", e => {
        e.preventDefault();
        e.stopPropagation();
        const href = elm.attributes["href"].value;
        let target = $(href);

        if (target.length) {
          this.move(target, elm);
        }
      });
    });
  }

  /**
   * 移動処理
   * @return {undefined}
   */
  move(target, elm) {
    const pos = this.getPos(target, elm);
    $('body,html').animate({ scrollTop: pos }, this.speed, 'swing');
  }

  /**
   * 移動先の位置を返却
   * @return {number}
   */
  getPos(target, elm) {
    const offset = elm.getAttribute("data-offset");

    let pos = target.offset().top;
    if (offset) {
      pos += parseInt(offset);
    }

    return pos;
  }
}
