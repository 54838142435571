export default class Modal {
  constructor() {
    this.container = document.getElementsByClassName('js-modal');
    this.init();
  }

  init() {
    if (!this.container.length) return false;
    var sc = 0;
    $(this.container).on('click',function(e){
      e.preventDefault();
      //表示
      sc = $(window).scrollTop();
      var target = $(this).attr('href');
      $('body').toggleClass('is-fixed');
      $('body').css({'top': '-' + sc + 'px'});
      $(target).show();
      $('.js-mapModal').addClass('is-active');
      $('.js-mapModal').find('.mapModal__inner').fadeIn();
      $('.js-mapModal').fadeIn();
    });

    //非表示
    $('.js-mapModalClose,.js-mapModal-bg').on('click',function(e){
      e.preventDefault();
      $('body').toggleClass('is-fixed');
      $('body').css({'top': 0});
      $(window).scrollTop(sc);
      $('.js-mapModalContents').hide();
      $('.js-mapModal').removeClass('is-active');
      $('.js-mapModal').find('.mapModal__inner').fadeOut();
      $('.js-mapModal').fadeOut();
    });
  }
}
